import React from "react";
import "./Styles/InCartComponent.css";

const InCartComponent = () => {
  return (
    <>
     
    </>
  );
};

export default InCartComponent;
