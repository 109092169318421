import React from "react";
import "./Styles/collab.css";

const CollabCard = ({ collection }) => {

  return (
    <div className="card-collab-component-main d-flex flex-column">
      <div className="img-hover-zoom">
        <img
          src={collection.collectionImage}
          alt="collection-img"
          height="150px"
          width="100%"
        />
      </div>
      <h5>{collection.collectionName} collection</h5>
    </div>
  );
};

export default CollabCard;
